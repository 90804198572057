<template>
  <div id="home">
    <vue-headful
      title="Edgeryders | Start"
      description="Welcome to Edgeryders."
    />

    <header :style="cssProps">
      <div class="image">
        <div class="home_menu"></div>

        <img src="@/assets/facewall-2.jpeg" alt="ER Profiles" />
      </div>
      <div class="wrapper">
        <div class="text">
          <h2>Welcome to Edgeryders</h2>
          <p>
            Whatever your challenge, chances are others are already working on a
            solution. We help your organization to harness the power of
            collective intelligence for wiser, more effective ways to work
            together and make decisions.
          </p>
          <div class="flex items-center mt-2">
            <a href="https://edgeryders.eu/c/campfire/237" class="action">
              Join our Community
            </a>
            <a
              href="mailto:nadia@edgeryders.eu"
              aria-label=""
              class="text-white underline font-semibold"
              >Contact Us</a
            >
          </div>
        </div>
      </div>
    </header>
    <div class="section" id="news">
      <div class="wrapper">
        <div class="home" @click="goHome()">Return home</div>

        <div class="section_nav">
          <h2 class="font-bold text-4xl pb-0 mb-0 text-left">All Activities</h2>
        </div>

        <masonry
          :cols="{ default: 3, 700: 1 }"
          :gutter="{ default: '40px', 700: '0px' }"
          class="slider"
        >
          <div
            class="project"
            style="
              border: 1px solid rgba(0, 0, 0, 0.04);
              padding: 25px 30px;
              margin: 0 0px 40px;
            "
            :style="'border-top: 2px solid #' + item.color"
            v-for="(item, index) in $store.projects"
            :key="index"
            @click="goTo('project', item.slug)"
          >
            <h3 class="item_category" :style="'color: #' + item.color">
              <img
                v-if="item.uploaded_logo"
                :src="'https://edgeryders.eu/' + item.uploaded_logo.url"
              />
              <p>{{ item.name }}</p>
            </h3>
            <div class="flex border-b mt-0 mb-3">
              <p
                class="text-xs border-r pr-2 py-2 font-bold"
                style="color: rgba(0, 0, 0, 0.8)"
              >
                Last Updated {{ getUpdated(item.id) }}
              </p>
              <a
                class="text-xs pl-2 py-2 font-bold underline"
                :href="'https://edgeryders.eu/c/' + item.id"
                target="_blank"
                style="color: rgba(0, 0, 0, 0.8)"
                >Discussion ({{ item.topic_count }} topics)</a
              >
            </div>

            <p class="inline info w-full" v-if="item.description">
              {{ getDescription(item.description) }}
            </p>
          </div>
        </masonry>
      </div>
    </div>
  </div>
</template>

<script>
import vueHeadful from "vue-headful";
import { bus } from "@/main";
import moment from "moment";
var YAML = require("yamljs");
export default {
  name: "App",
  data() {
    return {
      projects: null,
      cssProps: {
        backgroundImage: `url(${require("@/assets/lowpoly.png")})`,
      },
    };
  },
  components: {
    vueHeadful,
  },
  props: [],
  computed: {},
  methods: {
    getUpdated(id) {
      if (this.$store.projectData.length > 2) {
        var project = this.$store.projectData.filter(
          (x) => x.projectid == id
        )[0];
        return moment(String(project.updated)).format("DD/MM/YY");
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  filters: {
    dateLong: function (value) {
      return moment(String(value)).format("dddd, MMMM Do");
    },
  },
  created() {
    this.fetchCategories();
    this.$store.type = "projects";
  },
};
</script>
<style lang="scss" scoped>
div /deep/ .swiper-pagination-bullet-active {
  background-color: white !important;
  color: white !important;
}
header {
  @apply relative flex;
  overflow: hidden;
  height: 500px;
  .home_menu {
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    backdrop-filter: blur(1px);
    height: 60px;
    top: 0;
    right: 0;
    width: 100%;
  }
  .image {
    clip-path: polygon(15% 0%, 100% 0%, 100% 100%, 0% 100%);
    @apply inset-y-0 top-0 right-0 z-0 w-full absolute;
    width: 55%;
    img {
      @apply object-cover w-full h-full;
    }
  }
  .wrapper {
    @apply relative flex px-8 mx-auto items-center justify-start w-full h-full;
    max-width: 1280px;
    .text {
      width: 42%;
      h2 {
        @apply mb-5 font-sans text-4xl font-bold tracking-tight text-white;
      }
      p {
        @apply pr-5 mb-5 text-xl text-white;
      }
      a.action {
        @apply px-6 py-3 mr-6 text-base font-bold orangered rounded-full shadow-md bg-white;
        white-space: nowrap;
      }
    }
  }
}

.section#events {
  .upcoming {
    @apply flex w-full flex-col pr-24;
    h2 {
      @apply mb-4;
    }
    .event_item {
      @apply mb-4 inline-block border-b pb-2;
      h3 {
        @apply text-lg  tracking-tight font-bold;
        &.date {
          @apply text-xl font-bold;
          color: RGBA(252, 100, 40, 1);
        }
      }
      &:hover {
        h3 {
          @apply underline;
          cursor: pointer;
        }
        h3.date {
          text-decoration: none;
        }
      }
    }
  }

  .events_calendar {
    margin-top: -3rem;
    h3 {
      &:hover {
        cursor: pointer;
      }
    }
  }
  .calendar {
    flex-basis: 50%;
  }
}

.section {
  @apply w-full py-14;
  .wrapper {
    @apply mx-auto;
    width: 85%;
    max-width: 1220px;
  }
  .section_nav {
    @apply flex w-auto mb-8 border-b justify-between;
    width: 300px;
    h2 {
      @apply mb-4;
    }
  }
  h2 {
    @apply mb-6 pb-0;
  }
}
#news {
  .swiper-container {
    margin-left: -1rem;
  }
}
.news_item {
  @apply pl-4;
  .text {
    @apply border-r pr-10;
    h3.date {
      text-decoration: none;
      @apply text-2xl font-light;
      color: black;
    }
    h3 {
      @apply text-xl tracking-tight leading-tight;
    }
    &:hover {
      cursor: pointer;
      h3 {
        @apply underline;
      }
    }
  }

  &.swiper-slide-next + .news_item {
    .text {
      border: none;
    }
  }
  h3.item_category {
    @apply bg-gray-100 mb-0 float-left inline-flex items-center font-bold rounded-full px-3 pt-2;
    font-size: 12px;
    margin-bottom: 0 !important;
    white-space: nowrap;
    color: white;
    img {
      width: auto;
      height: 15px;
      flex-shrink: 0;
      @apply mr-1;
    }
    &:hover {
      cursor: pointer;
    }
  }
}

h3 {
  color: black;
  @apply font-bold mb-0 leading-tight;
  &.date {
    color: orangered;
    @apply font-bold mb-1;
  }
}

ul.start_menu {
  @apply inline-flex mb-4 px-0 border-t border-b;
  li {
    @apply px-5 py-4 font-bold text-lg inline-flex items-center;
    color: #000;
    border-right: 0.5px solid rgba(0, 0, 0, 0.09);
    color: rgba(0, 0, 0, 0.7);
    &.active {
      @apply underline;
      color: black;
      background: rgba(0, 0, 0, 0.01);
    }

    &:hover {
      @apply underline;
      cursor: pointer;
      color: black;
    }
    .icon {
      @apply mr-2;
      width: 25px;
      img {
        width: auto;
        height: 20px;
      }
    }
  }
}

.orangered {
  color: orangered;
}
.bg_orangered {
  background: RGBA(252, 100, 40, 1);
}

.menu {
  .links {
    display: none;
  }
  &.active {
    .links {
      display: flex;
    }
  }
}
.logo {
  &.er {
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 500 500' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 250a250 250 0 11500 0 250 250 0 01-500 0zm97-103v106h42v-16h-26v-31h19v-16h-19v-27h26v-16zm80 91c6 0 8-4 8-11v-52c0-8-4-12-13-12h-4v75zm-25-91h23c19 0 27 10 27 29v48c0 20-8 29-26 29h-24zm63 25c0-17 9-27 25-27s24 9 24 27v10h-16v-11c0-8-3-11-8-11-6 0-9 3-9 11v59c0 7 3 11 9 11 5 0 8-4 8-11v-17h-10v-13h26v53h-8l-3-7c-4 7-9 10-16 10-14 0-22-11-22-30zm64-25h43v16h-26v27h18v16h-18v31h26v16h-43zM112 381H95V275h24c18 0 26 8 26 30 0 13-3 21-10 26l12 50h-17l-10-45h-8zm0-60v-31h6c8 0 10 3 10 15 0 11-2 16-10 16zm53-46l10 42h1l10-42h17l-19 62v44h-16v-42l-20-64zm69 90c6 0 9-4 9-11v-51c0-9-4-13-13-13h-4v75zm-24-90h23c18 0 27 9 27 29v47c0 20-8 30-26 30h-24zm136 46v-31h6c8 0 10 3 10 15 0 11-2 16-10 16zm-72-46h43v15h-26v28h19v15h-19v32h26v16h-43zm72 106h-16V275h24c17 0 25 8 25 30 0 13-3 21-10 26l12 50h-17l-10-45h-8zm57-30c1 11 4 16 11 16 6 0 10-4 10-10 0-9-6-14-15-23-12-12-20-21-20-35 0-15 11-26 25-26s24 10 25 26l-16 2c-1-9-3-13-9-13-5 0-9 4-9 10 0 9 7 16 17 25 11 11 18 19 18 34s-11 26-26 26-26-11-27-29zm-65-172v-42h-42a42 42 0 1142 42z' fill='white' fill-rule='evenodd'/%3E%3C/svg%3E");
    background-size: cover;
    width: 70px;
    height: 70px;
    background-repeat: no-repeat;
    @apply pl-4;
  }
}

.card {
  @apply flex flex-col justify-between overflow-hidden text-left transition-shadow duration-200 bg-white border group hover:shadow-2xl;
  text-align: center !important;
}

.button {
  @apply rounded-full px-4 py-3 font-bold bg-white inline-block mt-4;
  font-size: 14px;
  text-decoration: none !important;
  &:hover {
    cursor: pointer;
    @apply shadow-lg;
  }
}

.read_more {
  @apply block mt-4 w-full pb-4 border-b font-bold text-xl;

  border-color: rgba(255, 255, 255, 0.3);
  &:hover {
    cursor: pointer;
    text-decoration: underline !important;
  }
}

.section#remote_work {
  background: radial-gradient(
    circle,
    rgba(26, 35, 121, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  background-position: -300px 0;
  background-size: 100%;
  .text {
    @apply w-full pl-8;
  }
}

.text {
  p {
    @apply mb-4;
  }
}

.section#projects {
  @apply relative flex w-full py-0;
  h2 {
    color: white;
    top: 40px;
    @apply pt-4;
  }
  .slider {
    height: 500px;
    width: 100%;
    @apply relative;
    z-index: 99;
    border-radius: 0;
    .slide {
      background-size: cover !important;
      overflow: hidden;
      border-radius: 0;
      min-height: 350px;
      @apply relative p-6 pb-0  flex items-center justify-center;
      height: auto;
      &.project {
        position: relative;
        overflow: hidden;
        padding-top: 50px;

        &:before {
          position: absolute;
          width: 100%;
          height: 100%;
          background: red;
          content: "";
          left: 0;
          top: 0;
          z-index: 9;
          background: radial-gradient(
            circle,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.2) 80%,
            rgba(0, 0, 0, 0.4) 100%
          );
        }
        .button {
          font-size: 16px;
        }
        img.logo {
          opacity: 0.8;
          height: 300px;
          margin-left: 7%;
          margin-top: 40px;
          width: auto;
        }
        .text {
          @apply flex flex-col relative items-start px-20;
          width: 50%;
          z-index: 999;
          color: white;
          &:hover {
            cursor: pointer;
          }
          h3 {
            @apply text-3xl relative flex inline-block items-center border-b pb-4 mb-1 font-bold;
            color: white;
            width: 100%;
            img {
              height: 50px;
              width: auto;
              @apply mr-4 hidden;
            }
          }
          p {
            @apply text-xl  mt-4;
          }
        }
      }
    }
  }
}

#events {
  div /deep/ .vuecal {
    &.vuecal--month-view {
      .vuecal__cell--has-events {
        border-bottom: 2px solid orangered;
        color: orangered;
        @apply font-bold;
      }
    }
    .vuecal__cell {
      background: rgba(255, 255, 255, 0.1);
    }

    .vuecal__cell-events-count {
      background: orangered;
    }
    .weekday-label {
      color: black;
    }
    .today {
      .weekday-label {
        color: orangered;
        font-weight: 900;
      }
    }
  }
}

.section#collective_intelligence {
  .text {
    @apply w-full pl-8;
  }
}

@media only screen and (max-width: 1020px) {
  header {
    @apply flex flex-col;
    height: auto;
    .wrapper {
      height: auto;
      .text {
        width: 100%;
        padding: 40px 0 50px;
      }
    }
    .image {
      clip-path: polygon(0 0, 100% 0%, 100% 85%, 0 100%);
      top: 0;
      position: relative;
      width: 100%;
      height: 200px;
    }
  }

  .section {
    .wrapper {
      width: 85%;
      overflow: hidden;
    }
  }

  .section#news {
    .slider {
      margin-left: 0;
      .news_item {
        border: none;
        padding-left: 0;
        .text {
          padding: 0;
          border: none;
        }
      }
    }
  }
  .section#events {
    .events_calendar {
      display: none;
    }
    .upcoming {
      margin: 0;
    }
  }
  .section#projects {
    height: auto;
    img.logo {
      display: none;
    }
    h2 {
      display: none;
    }
    .slider {
      height: auto;
      .slide.project {
        @apply flex flex-col p-0 py-12 justify-start;
        .text {
          width: 85% !important;
          padding: 0;
          @apply mx-auto;
          h3 {
            @apply text-3xl;
            border-color: rgba(255, 255, 255, 0.2);
            img {
              height: 40px;
              display: inline-block;
            }
          }
          p {
            font-size: 1rem;
            @apply text-lg leading-normal;
          }
        }
      }
    }
  }
  .section#collective_intelligence {
    .text {
      @apply mt-4 pl-0;
    }
  }
  .section#remote_work {
    .wrapper {
      width: 100%;
      padding: 0 7.5%;
    }
    .text {
      @apply mt-4 pl-0;
    }
  }
}

.project {
  @apply pr-10 mb-10;
  transition: all 0.1s ease;
  background: rgba(0, 0, 0, 0.01);
  h3 {
    @apply flex border-b pb-3 items-center;
    height: 40px;
    img {
      @apply mr-2;
      height: 30px;
      width: auto;
    }
  }
  &:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.03) !important;
    border: 1px solid rgba(0, 0, 0, 0.8);
    h3 {
      @apply underline;
    }
  }
}

p.info {
  background-size: 14px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 0px !important;
  @apply font-normal text-base tracking-tight;
}

.home {
  padding: 10px 15px 10px 43px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 30' fill='none'%3E%3Cpath d='M12 18.6L10.5 20l-6.9-6.6a2 2 0 010-2.8l7-6.6L12 5.4 6.1 11H21v2H6.1l5.9 5.6z' fill='%23000'/%3E%3C/svg%3E")
    no-repeat 14px 11.5px rgba(0, 0, 0, 0.02);
  background-size: 22px;
  @apply rounded-full mb-8 font-bold inline-block;
  border: 1px solid #ddd;

  &:hover {
    border: 1px solid #ddd;
    cursor: pointer;
  }
}

@media only screen and (max-width: 600px) {
}
</style>

